<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Audit and Inspection</h2>
        <div v-if="audits.length > 0">
            <v-card elevation="2" outlined class="mb-3" v-for="audit in audits" v-bind:key="audit.id">
            <v-card-title style="padding:10px">
                <h4 class="primary--text" @click="reviewAudit(audit)">{{ audit.audit_id }}</h4>
            </v-card-title>
            </v-card>
        </div>
        <div v-else>
            <v-alert type="error">No audits and inspections found</v-alert>
            <v-btn small style="text-transform: capitalize;" class="primary" @click="refreshAudits">Refresh</v-btn>
        </div>
    </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
export default {
    name:'AuditOverview',
    computed: {
        ... mapGetters ( {audits : "getAllAudits"})
    },
    methods: {
        ... mapActions (['fetchAllAudits']),
        refreshAudits () {
            let user_p = JSON.parse(localStorage.getItem('user'))
            this.fetchAllAudits(user_p.id)
            location.reload()
        },
        reviewAudit (value) {
            this.$store.commit('setCurrentAudit',value)
            this.$router.push('/audit/review')
        },
    },
    mounted () {
        let user = JSON.parse(localStorage.getItem('user'))
        
        if(user == null){
            this.$router.push('/')
        }else{
            this.fetchAllAudits(user.id)
        }
    }

}
</script>